import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

const baseQuery = fetchBaseQuery({
  baseUrl: "http://ec2-23-20-156-92.compute-1.amazonaws.com:9080/api/",
  prepareHeaders: async (headers) => {
    return headers;
  },
});

//used for token handle and common response and error

const staggeredBaseQueryWithBailOut = retry(
  async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === "FETCH_ERROR") {
      toast.error("Network error", {
        position: "top-right",
      });
    }

  
    return result;
  },
  {
    maxRetries: 0,
  }
);

export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ['division'],
  endpoints: () => ({}),
});
